import React from "react"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

import Address from "./Address"

import * as styles from "./styles.module.scss"

export default function Navigation() {
  const breakpoints = useBreakpoint()

  return (
    <div className={styles.navigation}>
      <div className={`row ${styles.wrapper}`}>
        <div className="col-12 col-md mb-2 mb-md-0">
          <h6 className="mb-md-4">
            <a href="https://equitybee.com/investors">For investors</a>
          </h6>
        </div>
        <div className="col-12 col-md mb-2 mb-md-0">
          <h6 className="mb-md-4 ">
            <span
              className="collapsed"
              aria-expanded="false"
              data-bs-toggle="collapse"
              data-bs-target="#toggleLearn"
              aria-label="Toggle Learn"
            >
              Learn
            </span>
          </h6>
          <ul
            className={!breakpoints.md ? "collapse" : ""}
            id={!breakpoints.md ? "toggleLearn" : ""}
          >
            <li>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://blog.equitybee.com/"
              >
                Blog
              </a>
            </li>
            <li>
              <a href="https://equitybee.com/faq">FAQ</a>
            </li>
          </ul>
        </div>
        <div className="col-12 col-md mb-2 mb-md-0">
          <h6 className="mb-md-4">
            <span
              className="collapsed"
              aria-expanded="false"
              data-bs-toggle="collapse"
              data-bs-target="#toggleCompany"
              aria-label="Toggle Company"
            >
              Company
            </span>
          </h6>
          <ul
            className={!breakpoints.md ? "collapse" : ""}
            id={!breakpoints.md ? "toggleCompany" : ""}
          >
            <li>
              <a href="https://equitybee.com/about-us">About</a>
            </li>
            <li>
              <a href="https://equitybee.com/careers">Careers</a>
            </li>
            <li>
              <a href="https://equitybee.com/press">Press</a>
            </li>
            <li>
              <a href="https://equitybee.com/contact-us">Contact Us</a>
            </li>
          </ul>
        </div>
        <div className="col-12 col-md">
          <Address className="mb-4">
            <h6 className="mb-2">Palo Alto Office</h6>
            <p className="mb-0">
              955 Alma St., Suite A<br />
              Palo Alto, California 94301
              <br /> Tel: 650-847-1149
            </p>
          </Address>
          <Address>
            <h6 className="mb-2">Tel Aviv Office</h6>
            <p className="mb-0">
              30 Shlomo Ibn Gabirol St.
              <br />
              Tel Aviv-Yafo, Israel 6407807
            </p>
          </Address>
        </div>
      </div>
    </div>
  )
}

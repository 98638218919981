import React from "react"
import { LocalizedLink } from "gatsby-theme-i18n"

import logoEquitybeeGuides from "/src/assets/images/logo-equitybee-guides.svg"
import logoEquitybee from "/src/assets/images/logo-equitybee.svg"

import * as styles from "./styles.module.scss"

export default function Logo({ equitybeeOnly }) {
  const src = equitybeeOnly ? logoEquitybee : logoEquitybeeGuides

  return (
    <LocalizedLink className={styles.logo} to="/">
      <img src={src} alt="Equitybee | Guides" />
    </LocalizedLink>
  )
}

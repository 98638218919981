const simpleNavConfig = [
  {
    title: "Terms of Service",
    href: "https://equitybee.com/terms",
  },
  {
    title: "Privacy Policy",
    href: "https://equitybee.com/privacy",
  },
]

export default simpleNavConfig

import React from "react"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

import Logo from "/src/components/Logo"
import Socials from "/src/components/Socials"
import SimpleNav from "/src/components/SimpleNav"

import Navigation from "./Navigation"
import Copyright from "./Copyright"
import BtnLogin from "./BtnLogin"

import simpleNavConfig from "./simpleNavConfig"

import * as styles from "./styles.module.scss"

export default function Footer() {
  const breakpoints = useBreakpoint()

  return (
    <footer className={styles.footer}>
      <div className="container-xl">
        {!breakpoints.md && (
          <div className="mb-5">
            <Logo equitybeeOnly />
          </div>
        )}
        <Navigation />
        <div className="row justify-content-start align-items-center mb-3">
          <div className="col-auto ">
            <Socials />
          </div>
          <div className="col">
            <BtnLogin />
          </div>
        </div>
        <SimpleNav navConfig={simpleNavConfig} className="mb-4" />
        <Copyright />
      </div>
    </footer>
  )
}

import React from "react"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

import ToggleMenu from "/src/components/ToggleMenu"
import Logo from "/src/components/Logo"
import Socials from "/src/components/Socials"

import * as styles from "./styles.module.scss"

export default function TopBar() {
  const breakpoints = useBreakpoint()

  return (
    <div className={`container-xl py-4 ${styles.topBar}`}>
      <div className="d-flex justify-content-between align-items-center">
        {!breakpoints.lg && <ToggleMenu />}

        <Logo />

        {breakpoints.lg && (
          <div className="d-flex align-items-center">
            {/*<div>Search</div>*/}
            <Socials className="ms-3" />
          </div>
        )}
      </div>
    </div>
  )
}

import React from "react"

import Sidebar from "/src/components/Sidebar"

import * as styles from "./styles.module.scss"

export default function Main({ children }) {
  return (
    <main className={`container-xl mb-50 mb-md-100 ${styles.main}`}>
      <div className="d-md-flex justify-content-md-between">
        <div className={`me-xl-5 mb-5 mb-md-0 ${styles.content}`}>
          {children}
        </div>
        <Sidebar className="ms-md-5" />
      </div>
    </main>
  )
}

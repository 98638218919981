import React from "react"

import ToggleMenuIcon from "/src/components/buttons/ToggleMenuIcon"
import Navigation from "/src/components/navigation/Mobile"

import * as styles from "./styles.module.scss"

export default function ToggleMenu() {
  return (
    <div>
      <ToggleMenuIcon
        aria-expanded="false"
        data-bs-toggle="collapse"
        data-bs-target="#toggleMenuContent"
        aria-label="Toggle navigation"
      />

      <div
        className={`collapse ${styles.toggleMenuContent}`}
        id="toggleMenuContent"
      >
        <div className="pb-5 overflow-hidden">
          <div className={`p-3 ${styles.wrapper}`}>
            <Navigation />
          </div>
        </div>
      </div>
    </div>
  )
}

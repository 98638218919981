import React from "react"

import Header from "/src/components/Header"
import Main from "./Main"
import Footer from "/src/components/Footer"

export default function Layout({ children }) {
  console.info("process.env.NODE_ENV", process.env.NODE_ENV)

  return (
    <>
      <Header />
      <Main>{children}</Main>
      <Footer />
    </>
  )
}

import { useIntl } from "react-intl"

const useNavigationConfig = () => {
  const intl = useIntl()

  return [
    {
      title: intl.formatMessage({ id: "nav-home" }),
      to: "/",
    },
    {
      title: intl.formatMessage({ id: "nav-leaving-startup" }),
      to: "/leaving-startup",
    },
    {
      title: intl.formatMessage({ id: "nav-liquidity-event" }),
      to: "/liquidity-event",
    },
  ]
}

export default useNavigationConfig

import React from "react"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

import TopBar from "/src/components/TopBar"
import Navigation from "/src/components/navigation/Desktop"

import * as styles from "./styles.module.scss"

export default function Header() {
  const breakpoints = useBreakpoint()

  return (
    <header className={styles.header}>
      <TopBar />
      {breakpoints.lg && <Navigation />}
    </header>
  )
}

import React from "react"

import "./styles.scss"

export default function ToggleMenuIcon(props) {
  return (
    <div className="toggleMenuIcon collapsed" {...props}>
      <span></span>
    </div>
  )
}

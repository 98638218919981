import React from "react"

import * as styles from "./styles.module.scss"

export default function Copyright() {
  return (
    <div className={`pt-4 ${styles.copyright}`}>
      <div className={styles.wrapper}>
        <p>
          This site is operated by Equitybee Inc. (“Equitybee”) , which is not a
          registered broker-dealer. Equitybee does not give investment advice,
          endorsement, analysis or recommendations with respect to any
          securities. All securities posted here are being offered by, and all
          information included on this site is the responsibility of, the
          applicable issuer of such securities. Equitybee has not taken steps to
          verify the adequacy, accuracy, or completeness of the information.
          Neither Equitybee nor any of its officers, directors, agents and
          employees makes any warranty, express or implied, of any kind related
          to the adequacy, accuracy or completeness of any information on this
          site or the use of information on this site. By accessing this site
          and any pages thereof, you agree to be bound by the{" "}
          <a href="https://equitybee.com/terms">Terms of Use</a> and{" "}
          <a href="https://equitybee.com/privacy">Privacy Policy</a>.
        </p>
        <p>
          <a href="https://equitybee.com/il-tax-ruling-inv">
            Compliant with ITA ruling
          </a>
        </p>
        <p className={styles.marked}>
          Copyright © 2022 Equitybee, Inc. All rights reserved.
        </p>
      </div>
    </div>
  )
}

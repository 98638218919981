import * as React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { LocaleContext } from "gatsby-theme-i18n"

import equitybee from "/src/assets/images/equitybee.jpg"

const Seo = ({ description, title, children }) => {
  const lang = React.useContext(LocaleContext)
  const dir = lang === "he" ? "rtl" : "ltr"

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title
  const siteUrl = site.siteMetadata?.siteUrl
  const image = equitybee

  return (
    <Helmet
      htmlAttributes={{
        lang,
        dir,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
    >
      <meta name="description" content={metaDescription} />

      <meta property="og:type" content="website" />
      <meta property="og:url" content={siteUrl} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:image" content={image} />
      <meta property="og:image:width" content="1080" />
      <meta property="og:image:height" content="650" />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="Equitybee" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={metaDescription} />
      <meta name="twitter:image" content={image} />

      {children}
    </Helmet>
  )
}

export default Seo

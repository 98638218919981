import React, { useState } from "react"

import Box from "/src/components/Box"
import RegistrationPopup from "/src/components/popups/RegistrationPopup"
import { useIntl } from "react-intl"

import * as styles from "./styles.module.scss"

export default function Sidebar({ className }) {
  const intl = useIntl()

  const [isRegistrationPopupOpen, setIsRegistrationPopupOpen] = useState(false)
  const toggleRegistrationPopup = () =>
    setIsRegistrationPopupOpen(!isRegistrationPopupOpen)

  return (
    <aside className={`${className} ${styles.sidebar}`}>
      <div className={`p-4 ${styles.wrapper}`}>
        <Box withMinHeight withTopLeftCorner className="mb-5 mw-250 mx-auto">
          <h4 className="fw-normal">
            {intl.formatMessage({ id: "sidebar_box-need-help" })}
          </h4>
          <h3 className="mb-3">
            {intl.formatMessage({ id: "sidebar_box-financing-your-options" })}
          </h3>
          <div className="row justify-content-center">
            <div className="col-10">
              <a
                className="btn btn-primary"
                href="http://equitybee.com/employees?ref=guide&cat=equitybee.com"
                rel="noopener noreferrer"
                target="_self"
              >
                {intl.formatMessage({
                  id: "sidebar_box-for-further-details",
                })}
              </a>
            </div>
          </div>
        </Box>
        <Box withMinHeight withTopLeftCorner className="mw-250 mx-auto">
          <h4 className="mb-3 fw-normal">
            {intl.formatMessage({
              id: "sidebar_box2-get-updates",
            })}
            <br />
            <strong>
              {intl.formatMessage({
                id: "sidebar_box2-get-updates2",
              })}
            </strong>
          </h4>
          <div className="row justify-content-center">
            <div className="col-10">
              <button
                className="btn btn-danger"
                onClick={toggleRegistrationPopup}
              >
                {intl.formatMessage({
                  id: "sidebar_box2-enrollment",
                })}
              </button>
            </div>
          </div>
        </Box>
      </div>

      <RegistrationPopup
        isRegistrationPopupOpen={isRegistrationPopupOpen}
        closePopup={toggleRegistrationPopup}
      />
    </aside>
  )
}

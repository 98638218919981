import * as styles from "./styles.module.scss"

const socialConfig = [
  {
    title: "LinkedIn",
    className: styles.linkedin,
    href: "https://www.linkedin.com/authwall?trk=bf&amp;trkInfo=AQFnF_-NaSG8RQAAAWyy2eSAGpjlRilAr0TORgr9XWZVmHaH-Jw302eM0q_Zz4OBjDAc_4oYJXjUnXjH00QwZaGqbPtyDnPa_lwfvH6nmwgWX8CANJdSgkP3Bj5soWDLqfCcjXs=&amp;originalReferer=&amp;sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Fequitybee%2F",
  },
  {
    title: "facebook",
    className: styles.facebook,
    href: "https://www.facebook.com/EquityBeeCom/",
  },
  {
    title: "instagram",
    className: styles.instagram,
    href: "https://www.instagram.com/equitybee/",
  },
  {
    title: "twitter",
    className: styles.twitter,
    href: "https://twitter.com/EquityBee",
  },
  {
    title: "youtube",
    className: styles.youtube,
    href: "https://www.youtube.com/channel/UCTyanjgFHXj3zHY16GNF2RA",
  },
]

export default socialConfig

import React from "react"
import { LocalizedLink } from "gatsby-theme-i18n"

import useNavigationConfig from "../useNavigationConfig"

import * as styles from "./styles.module.scss"

export default function Navigation() {
  const navData = useNavigationConfig()

  return (
    <div className={styles.navigation}>
      <nav className={`container-xl ${styles.wrapper}`}>
        {navData.map(i => (
          <LocalizedLink activeClassName={styles.active} to={i.to} key={i.to}>
            {i.title}
          </LocalizedLink>
        ))}
      </nav>
    </div>
  )
}

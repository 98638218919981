import React from "react"

import * as styles from "./styles.module.scss"

export default function BtnLogin() {
  return (
    <a className={styles.btnLogin} href="https://equitybee.com/login">
      <strong>Log In</strong>
    </a>
  )
}

import React from "react"

import config from "./socialConfig"

import * as styles from "./styles.module.scss"

export default function Socials({ className }) {
  return (
    <ul className={`${className} ${styles.socials}`}>
      {config.map(i => (
        <li key={i.title} className="ms-2">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={i.href}
            className={i.className}
          >
            {i.title}
          </a>
        </li>
      ))}
    </ul>
  )
}

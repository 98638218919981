import React from "react"

import * as styles from "./styles.module.scss"

export default function SimpleNav({ navConfig, className }) {
  return (
    <ul className={`${className} ${styles.simpleNav}`}>
      {navConfig.map(i => (
        <li key={i.title}>
          <a href={i.href}>{i.title}</a>
        </li>
      ))}
    </ul>
  )
}
